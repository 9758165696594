<template>
    <div>
        <CRow>
        <CCol md="2">
        </CCol>
        <CCol md="8">
            <CCard>
            <CCardHeader>
                <strong>New Opportunity</strong>
            </CCardHeader>
            <CCardBody>
                <CForm class="needs-validation" novalidate @submit.prevent="submitForm">
                    <div v-if="showErrors" :key="showErrors">
                        <CListGroup >
                            <div v-for="(error) in errors" :key="error.message">
                                <CAlert show color="danger">
                                {{ error.message }}
                                </CAlert>
                            </div>
                        </CListGroup>
                    </div>
                <CInput
                    description="Enter as much of the name as we know"
                    label="Name"
                    placeholder="Customer name"
                    horizontal
                    v-on:update:value="addNameInput"
                    addLabelClasses="labelClass"
                    :isValid="checkName"
                    invalidFeedback="Name must be at least 3 characters"
                />
                <div role="group" class="form-group form-row">
                <label for="emailInputId" class="labelClass col-form-label col-sm-3" >Email</label>
                <div class="col-sm-9">
                <input
                    id="emailInputId"
                    class="form-control"
                    type="text"
                    placeholder="Enter email address"
                    horizontal
                    v-model="email"
                    v-on:update:value="addEmailInput"
                    :isValid="checkEmail"
                />
                <div class="invalid-feedback">Must be a valid email address</div>
                </div>
                </div>
                <CInput
                    label="Phone"
                    placeholder="Preferrably a phone number"
                    horizontal
                    v-on:update:value="addMobileInput"
                    addLabelClasses="labelClass"
                    :isValid="checkMobile"
                    invalidFeedback="Must be a valid Australian phone number"
                />
                <CSelect
                    label="Opportunity type"
                    horizontal
                    :value.sync="selectedOpportunityType"
                    :options="opportunityTypeList"
                    v-on:update:value="opportunityTypeUpdated"
                    placeholder="Please select"
                    addLabelClasses="labelClass"
                />
                <CSelect
                    label="Why Enrich?"
                    horizontal
                    :value.sync="selectedOpportunitySource"
                    :options="opportunitySourceList"
                    v-on:update:value="sourceUpdated"
                    placeholder="Please select"
                    addLabelClasses="labelClass"
                />
                <CInput
                    label="why Enrich description"
                    :disabled="sourceDescriptionDisabled"
                    :key="sourceDescriptionDisabled"
                    placeholder="More detail about how customer found Enrich"
                    horizontal
                    v-on:update:value="addSourceDescriptionInput"
                    addLabelClasses="labelClass"
                />
                <CTextarea
                    label="Notes"
                    placeholder="Notes..."
                    v-on:update:value="addNoteInput"
                    horizontal
                    rows="3"
                    addLabelClasses="labelClass"
                />
                <CRow class="float-left">
                    <CCol >
                        <CButton color="primary" type="submit" class="mr-1">Create opportunity</CButton>
                    </CCol>
                    <CCol >
                        <CButton
                            color="secondary"
                            v-on:click="cancel"
                            class="mr-1"
                        >Cancel</CButton>
                    </CCol>
                </CRow>
                </CForm>
            </CCardBody>
            <CCardFooter>
            </CCardFooter>
            </CCard>
        </CCol>
        </CRow>
        <CModal
        title="Creating Opportunity..."
        color="success"
        :show.sync="submitModal"
        >
        <template #footer-wrapper><span/></template>
                <CRow>
                    <CCol >
                    <div class="center-align">
                        <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span></p>
                    </div>
                    </CCol>
                </CRow>
        </CModal>
    </div>
</template>

<script>
import axios from 'axios';
import enrichAuth from '@/auth'

export default {

    name: 'NewOpportunity',
    data() {
        return {
            opportunityTypeList: ['Web', 'Email', 'Phone', 'Walk in'],
            selectedOpportunityType: null,
            selectedOpportunitySource: null,
            // opportunitySourceList: [
            //     {value: 'Google'},
            //     {value: 'Advertisement'},
            //     {value: 'Instagram'},
            //     {value: 'Facebook'},
            //     {value: 'Word of Mouth'},
            //     {value: 'Shop'},
            //     {value: 'Article'},
            //     {value: 'Other'}],
            opportunitySourceList: ['Google', 'Advertisement', 'Instagram', 'Facebook', 'Word of Mouth', 'Shop', 'Article', 'Other'],
            sourceDescriptionDisabled: true,
            errors: [],
            showErrors: false,
            email: null,
            name: null,
            note: null,
            phone: null,
            submitModal: false,
        }
    },
    methods: {
        setOpportunityType(event) {
            console.log('opportunityType: ' + JSON.stringify(event))
            this.resetErrors()
            this.opportunityType = event
        },
        addNoteInput(event) {
            console.log('addNote: ' + event)
            this.resetErrors()
            this.noteText = event
        },
        addNameInput(event) {
            console.log('addName: ' + event)
            this.resetErrors()
            this.name = event
        },
        addEmailInput(event) {
            // console.log('addEmail: ' + event)
            this.resetErrors()
            this.email = event
        },
        addMobileInput(event) {
            console.log('addMobile: ' + event)
            this.resetErrors()
            this.phone = event
        },
        addSourceDescriptionInput(event) {
            console.log('addSourceDescription: ' + event)
            this.resetErrors()
            this.sourceDescription = event
        },
        opportunityTypeUpdated(event) {
            console.log('opportunityTypeUpdated: ' + JSON.stringify(event))
            this.resetErrors()
            this.opportunityType = event
            // this.initOpportunitySourceList(this.opportunityType)
        },
        sourceUpdated(event) {
            console.log('sourceUpdated: ' + JSON.stringify(event))
            this.resetErrors()
            this.source = event
            if (this.source == 'Other' || this.source == 'Other Website') {
                this.sourceDescriptionDisabled = false;
            } else {
                this.sourceDescriptionDisabled = true;
            }
        },
        // initOpportunitySourceList(opportunityType) {
        //     console.log('in initOpportunitySourceList')
        //     // switch(opportunityType) {
        //     //     case 'Web':
        //     //         this.opportunitySourceList = ['Web']
        //     //         break;
        //     //     case 'Email':
        //     //         this.opportunitySourceList = ['Email']
        //     //         break;
        //     //     case 'Phone':
        //     //         this.opportunitySourceList = ['Phone']
        //     //         break;
        //     //     case 'Walk in':
        //     //         this.opportunitySourceList = ['Walk in']
        //     //         break;
        //     //     default:
        //     //         this.opportunitySourceList = []
        //     //     }
        //     this.opportunitySourceList = ['Google', 'Advertisement', 'Instagram', 'Facebook', 'Word of Mouth', 'Shop', 'Other Website', 'Other']
        //     console.log('this.opportunitySourceList is' + this.opportunitySourceList)
        // },
        createOpportunity() {
            console.log('createOpportunity!')
            let loggedInUser = enrichAuth.getLoggedInUser()
            this.submitModal = true
            const url = process.env.VUE_APP_CRM_PUB_API_ROOT + '/opportunities';
            console.log('post opportunity url: ' + url);
            let opportunity = {
                "email": this.email,
                "phone": this.phone,
                "enquirerName": this.name,
                "opportunityType": this.opportunityType,
                "opportunityMessage": this.noteText,
                "source": this.source,
                "sourceDescription": this.sourceDescription,
                "organisationToken": loggedInUser.organisation.token
            }
            this.$cognitoAuth.getIdToken((err, result) => {
                if (err) { 
                    this.addError(err);
                } else {
                    console.log('New Opportunity - post, axios token: ' + result)
                    this.$http.defaults.headers.common['Authorization'] = result;
                    console.log('about to Post new opportunity: ' + JSON.stringify(opportunity))
                    axios({ method: 'post', data: opportunity, url: url})
                        .then(response => {
                            console.log('Post opportunity, response: ' + JSON.stringify(response))
                            // this.opportunity = response.data
                            delete this.$http.defaults.headers.common['Authorization'];
                            this.$router.go(-1)
                        })
                        .then(status => {
                            console.log('post opportunity, axios status: ' + status)
                            this.status = status;
                        })
                        .catch(err => {
                            console.log('post opportunity, axios err: ' + err)
                            this.fetching = false;
                            this.addError(err)

                        })
                }
            })

        },
        cancel() {
            this.$router.go(-1)
        },
        submitForm() {

            console.log('**********************  in submitForm...')
            this.resetErrors()
            var check

            check = this.checkName(this.name)
            if (!check && check != null) {
                this.addErrorMessage('Name should be at least 3 characters');
                console.log('errors: ' + this.errors[0].message)
                return false;
            }

            if (!this.name) {
                this.addErrorMessage('Please enter a name');
                console.log('errors: ' + this.errors[0].message)
                return false;
            }

            if (!this.phone && !this.email) {
                this.addErrorMessage('We need either an email address or a phone number');
                console.log('errors: ' + this.errors[0].message)
                return false;
            }

            check = this.checkEmail(this.email)
            if (!check && check != null) {
                this.addErrorMessage('Email address is not valid');
                console.log('errors: ' + this.errors[0].message)
                return false;
            }
            
            check = this.checkMobile(this.phone)
            if (!check && check != null) {
                this.addErrorMessage('Phone number is not valid');
                console.log('errors: ' + this.errors[0].message)
                return false;
            }

            if (!this.opportunityType) {
                this.addErrorMessage('Please choose an opportunity type');
                console.log('errors: ' + this.errors[0].message)
                return false;
            }

            if (!this.source) {
                this.addErrorMessage('Please choose a value for "why Enrich?"');
                console.log('errors: ' + this.errors[0].message)
                return false;
            }

            if (this.source == 'Other' || this.source == 'Other Website') {
                if (!this.sourceDescription) {
                    this.addErrorMessage('Please add a value in "why Enrich description" to explain why the customer came to Enrich');
                    console.log('errors: ' + this.errors[0].message)
                    return false;
                }
            }

            this.errors = null
            this.createOpportunity()

        },
        addErrorMessage(m) {
            if (!this.errors) {
                this.errors = [];
            }

            this.errors.push({message: m});
            this.showErrors = true
        },
        addError(e) {
            if (!this.errors) {
                this.errors = [];
                console.log('init errors[]')
            }
console.log('add error: ' + e.message)
            this.errors.push(e);
            this.showErrors = true
        },
        checkName(name) {
            if (name) {
                return name.length > 2
            }
            return null
        },
        checkEmail(email) {
            // console.log('XXXXXXXXXX checkEmail: ' + email)
            if (email) {
                var emailRGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/
                var emailResult = email.match(emailRGEX) == null ? false : true
                // console.log('email check result: ' + emailResult)
                return emailResult
            }
            return null
        },
        checkMobile(phone) {
            if (phone) {
                console.log('check phone: ' + phone)
                var phoneRGEX = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
                var phoneResult = phone.match(phoneRGEX) == null ? false : true
                return phoneResult
            }
            return null
        },
        resetErrors() {
            this.errors = null
            this.showErrors = false;
        }

    }
}
</script>

<style>
.labelClass {
  text-align: right;
}
</style>
